<template>
  <div style="width: 100%">
    <!--    <ckeditor :editor="editor" :config="editorConfig" v-model="inputVal" :disabled="disabled" class="editorVWRCustom" v-bind:style="{ minHeight: minHeight+ 'px !important' }"></ckeditor>-->
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      v-model="inputVal"
      :disabled="disabled"
      class="editorVWRCustom"
    ></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";

import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import FontPlugin from "@ckeditor/ckeditor5-font/src/font";

import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";

import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";

import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";

export default {
  name: "EditorForVWR",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: "50",
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          HeadingPlugin,
          BoldPlugin,
          ItalicPlugin,
          Underline,
          FontPlugin,
          AlignmentPlugin,
          Clipboard,
          PasteFromOffice,
        ],
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "undo",
            "redo",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "|",
            "alignment:left",
            "alignment:right",
            "alignment:center",
            "alignment:justify",
          ],
          shouldNotGroupWhenFull: true,
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
        link: {
          decorators: {
            openInNewTab: {
              mode: "manual",
              label: "Open in a new tab",
              attributes: {
                target: "_blank",
              },
            },
          },
        },
      },
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style></style>
